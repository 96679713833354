/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { Link } from "gatsby";
import axios from "axios";

import Layout from "~components/Layout";
import { AppContext } from "~context/AppContext";

class PageNotFoundComponent extends Component {
  state = {
    url: false,
    username: false,
    gifLink: false
  };

  mounted = false;

  componentDidMount() {
    axios
      .get(
        `https://api.giphy.com/v1/gifs/random?api_key=${process.env.GATSBY_GIPHY_KEY}&tag=confused&rating=G`
      )
      .then(response => {
        const { data } = response.data;

        if (data && data.images && data.user) {
          this.setState({
            url: data.images.downsized_large.url,
            username: data.username,
            gifLink: data.url
          });
        }
      })
      .catch(error => {
        throw error;
      });
  }

  //

  render() {
    return (
      <Layout className="_404 relative">
        <section className="w-full h-full relative flex flex-col text-dark-grey grid">
          <h1
            className="f1 font-bold grid-end--12 animation-appear pb-8"
            style={{ animationDelay: `100ms` }}
          >
            You look lost.
          </h1>
          <h3
            className="f3 grid-end--12 animation-appear"
            style={{ animationDelay: `200ms` }}
          >
            Yes, this is a 404 not found page. <br /> Maybe you should go&nbsp;
            <Link className="underline" to="/">
              home
            </Link>
            .
          </h3>

          {this.state.url && this.state.username && this.state.gifLink && (
            <div className="grid-end--12 py-16 mx-auto animation-appear">
              <img
                style={{ animationDelay: `300ms` }}
                src={this.state.url}
                alt="Random photography gif"
              />

              <span
                className="b2"
                dangerouslySetInnerHTML={{
                  __html: `Random gif created by <a class="underline" href="${this.state.gifLink}">${this.state.username}</a>`
                }}
              ></span>
            </div>
          )}
        </section>
      </Layout>
    );
  }
}

const PageNotFoundPage = () => {
  return (
    <AppContext.Consumer>
      {appContext => <PageNotFoundComponent appContext={appContext} />}
    </AppContext.Consumer>
  );
};

export default PageNotFoundPage;
